import Cookies from 'js-cookie'

import {trackFacebookPageView} from '../facebook'
import {parselyPageView} from '../parsely'

export function trackPageView(url: string) {
  // trackGooglePageView has been removed since it records page changes automatically
  trackFacebookPageView()
  parselyPageView(url)
}

// Store the current URL in a cookie so that we have a record of the page
// referrer. We can't get this information from `next/router` so we're
// manually storing it. Eventually passed to analytics platforms like Parsely.
export function trackPageReferrer() {
  return Cookies.set('eoereferrer', document.referrer || window.location.href)
}
