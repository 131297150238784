import React, {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {useRouter} from 'next/router'
import {GetStaticPaths, GetStaticProps} from 'next/types'

import type {Block} from '@sanity/types'
import * as Sentry from '@sentry/nextjs'
import {useQuery} from '@tanstack/react-query'

import {ParselyResourceMetadata} from '../../components/head/ParselyMetadata'
import {ResourceComponent} from '../../components/resources/ResourceComponent'
import ResourceStickyFooterAd from '../../components/resources/ads/ResourceStickyFooterAd'
import ResourceMetadata from '../../components/resources/common/ResourceMetadata'
import {getPublishedResourceQuery} from '../../components/resources/queries'
import type {
  ResourceProjection,
  TrendingResourceResponse,
} from '../../components/resources/schemas'
import {getField} from '../../components/resources/utils'
import NPIForm from '../../components/sanity/NPIForm'
import {NpiAdsExperimentSettingsProvider} from '../../core/ads/contexts/NpiAdsExperimentSettingsProvider'
import {useAdTracking} from '../../core/ads/hooks/useAdTracking'
import {baseBackendUrl, getInfiniteScrollResources} from '../../core/api'
import {useUser} from '../../core/auth'
import {useIsInViewport} from '../../core/hooks/useIsInViewport'
import {
  parseLeadFormFromResource,
  parseTableOfContents,
} from '../../core/hooks/useResource'
import type {ResourceTableOfContents} from '../../core/hooks/useResource'
import {backendClient} from '../../core/sanity'
import {isPartiallyInViewport} from '../../core/utils'
import {trpc} from '../../utils/trpc'

export interface Resource extends ResourceProjection {
  storyCategory?: string
  resourceCategory?: string
  leadForm?: Block
  tableOfContents?: ResourceTableOfContents[]
}

interface ResourceProps {
  primaryResource: Resource
  preview: boolean
  initialInfiniteResources: Resource[]
}

interface ResourcePageContext {
  resource: Resource
  activeResource: Resource
  preview: boolean
}
export const ResourcePageContext = createContext<ResourcePageContext>(
  {} as ResourcePageContext,
)
export const ResourcePageProvider = ({
  children,
  resource,
  activeResource,
  preview,
}: {
  children: ReactNode
  resource: Resource
  activeResource: Resource
  preview: boolean
}) => {
  const {user} = useUser()
  const useGetNPIForm = trpc.sanity.getNPIForm.useQuery(undefined, {
    enabled: !resource.sponsor && !resource.customSponsors && !!user,
    refetchOnWindowFocus: false,
    retry: false,
  })
  return (
    <ResourcePageContext.Provider value={{resource, preview, activeResource}}>
      {useGetNPIForm.data && <NPIForm {...useGetNPIForm.data} />}
      {children}
    </ResourcePageContext.Provider>
  )
}

const ResourcePage = ({
  primaryResource,
  preview,
  initialInfiniteResources,
}: ResourceProps) => {
  const resource = primaryResource
  const router = useRouter()
  const [infiniteResources, setInfiniteResources] = useState([
    {id: primaryResource._id, resource: primaryResource},
  ])
  const [queuedResources, setQueuedResources] = useState<
    Array<{id: string; resource: Resource}>
  >(
    initialInfiniteResources.map((resource) => {
      return {id: resource._id, resource}
    }),
  )
  const [visibleResourceIndex, setVisibleResourceIndex] = useState(0)

  useAdTracking(resource.slug)

  /**
   * This monster useEffect hook is responsible for keeping track of which
   * resource is in the viewport. There's better ways to do this but I've run out of
   * my refactoring budget this sprint. --Dom 1/20/23
   */
  useEffect(() => {
    // Monitor which resource is currently in the viewport. When the visible
    // resource changes, replace the URL state and record a page hit.
    const handleScroll = () => {
      const getVisibleResource = () => {
        // Find all the resources are loaded on the page
        const loadedResources = Array.from(
          document.querySelectorAll('[data-resource-index]'),
        )
        // Filter to all the resources that are currently in the viewport
        const visibleResources = loadedResources.filter((element) =>
          isPartiallyInViewport(element),
        )
        // Find the bottom-most resource that is in the viewport
        const currentResourceIndex = Math.max(
          ...visibleResources.map(
            // TODO: Type this properly
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (element: any) => element.dataset.resourceIndex,
          ),
        )
        return {
          index: currentResourceIndex,
          infiniteResource: infiniteResources[currentResourceIndex],
        }
      }
      const {index, infiniteResource} = getVisibleResource()
      if (!infiniteResource) return
      if (index !== visibleResourceIndex) {
        setVisibleResourceIndex(index)
        // Update the browser URL with the new resource without. Uses `replace`
        // and `shallow: true` to avoid actually navigating to a new page or
        // running any server-side logic. We only add new browser history if
        // the new index is greater than the current index, which indicates
        // that the user is scrolling down the page. Without this check, we
        // run into a situation where history is pushed when the user hits the
        // back button, leading to weird history UX bugs.
        if (index > visibleResourceIndex) {
          void router.replace(
            `/resources/${infiniteResource.resource.slug}/?utm_medium=eoe:infinite-scroll`,
            undefined,
            {shallow: true},
          )
        }
      }
    }
    window.addEventListener('scroll', handleScroll, {passive: true})

    return () => window.removeEventListener('scroll', handleScroll)
  }, [router, visibleResourceIndex, infiniteResources])

  const enableInfiniteScroll = useMemo(() => {
    return (
      resource.enableInfiniteScroll &&
      !resource.sponsor &&
      !resource.stickyFooter &&
      !preview
    )
  }, [
    preview,
    resource.enableInfiniteScroll,
    resource.sponsor,
    resource.stickyFooter,
  ])

  const {refetch: refetchInfiniteResources} = useQuery<Resource[]>(
    [`recommendedResources_${resource.slug}`],
    getInfiniteScrollResources({
      roles: resource.roles,
      clinicalSpecialty: resource.clinicalSpecialty,
      primaryResourceSlug: resource.slug,
      templateType: resource.templateType,
    }),
    {
      refetchOnWindowFocus: false,
      enabled: enableInfiniteScroll && !initialInfiniteResources.length,
      onSuccess: (data) => {
        if (!initialInfiniteResources.length) {
          const queuedResources = data.slice(0, 2).map((resource) => {
            return {id: resource._id, resource, isActive: false}
          })
          setQueuedResources(queuedResources)
        }
      },
      onError: (err) => Sentry.captureException(err),
    },
  )

  /**
   * On mount and route change, reset infinite scroll state, fetch infinite scroll resources
   */
  useEffect(() => {
    setInfiniteResources([{id: primaryResource._id, resource: primaryResource}])
    setVisibleResourceIndex(0)
    if (!initialInfiniteResources.length) {
      setQueuedResources([])
      if (enableInfiniteScroll) {
        refetchInfiniteResources()
      }
    }
  }, [
    primaryResource,
    refetchInfiniteResources,
    enableInfiniteScroll,
    initialInfiniteResources,
  ])

  /**
   * Call displayNewResource() to load a new resource into infinite scroll
   */
  const displayNewResource = useCallback(() => {
    const newResource = queuedResources.shift()
    if (newResource) {
      setInfiniteResources((prevState) => [...prevState, newResource])
    }
  }, [queuedResources])

  /**
   * Logic to handle infinite scroll when the loadMoreRef enters the viewport
   */
  const loadMoreRef = useRef(null)
  const infiniteLoaderIsInViewport = useIsInViewport(loadMoreRef)
  useEffect(() => {
    if (infiniteLoaderIsInViewport && enableInfiniteScroll) {
      displayNewResource()
    }
  }, [
    infiniteLoaderIsInViewport,
    displayNewResource,
    resource,
    preview,
    enableInfiniteScroll,
  ])

  return (
    <div>
      <NpiAdsExperimentSettingsProvider>
        <ResourcePageProvider
          resource={resource}
          activeResource={infiniteResources[visibleResourceIndex].resource}
          preview={preview}
        >
          <ParselyResourceMetadata />
          <ResourceMetadata />
          {infiniteResources.map(({id, resource}, index) => {
            return (
              <div key={id} data-resource-index={index}>
                <ResourceComponent
                  isVisible={visibleResourceIndex === index}
                  resourceIndex={index}
                  resource={resource}
                  preview={preview}
                />
              </div>
            )
          })}
          {/* Load another infinite story when this hr enters the viewport */}
          <hr ref={loadMoreRef} style={{visibility: 'hidden'}} />
          <ResourceStickyFooterAd />
        </ResourcePageProvider>
      </NpiAdsExperimentSettingsProvider>
    </div>
  )
}

export const getStaticPaths: GetStaticPaths = async () => {
  // Statically generate trending resources. Currently defined as the top
  // 100 resources with the most views in the last 30 days. All other resources
  // are server-rendered on-demand.
  const response = await fetch(
    `${baseBackendUrl}/resources/trending-resources/`,
  )
  const data = (await response.json()) as TrendingResourceResponse
  return {
    paths: data.slugs.map((slug: string) => {
      return {params: {slug}}
    }),
    // { fallback: blocking } will server-render pages on-demand if the path
    // doesn't exist.
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps = async ({
  params,
  preview = false,
}) => {
  const queryParams = {slug: params?.slug}
  const primaryResource: Resource =
    await backendClient.fetch<ResourceProjection>(
      getPublishedResourceQuery,
      queryParams,
    )
  // https://nextjs.org/docs/api-reference/data-fetching/get-server-side-props#notfound
  if (!primaryResource) {
    return {
      notFound: true,
    }
  }
  const leadForm = parseLeadFormFromResource(primaryResource)
  if (leadForm) {
    primaryResource.leadForm = leadForm
  }
  const tableOfContents = parseTableOfContents(primaryResource)
  if (tableOfContents) {
    primaryResource.tableOfContents = tableOfContents
  }
  let initialInfiniteResources: Resource[] = []
  if (primaryResource.infiniteScrollSlugs) {
    initialInfiniteResources = await Promise.all(
      primaryResource.infiniteScrollSlugs.map((slug) => {
        return backendClient.fetch<ResourceProjection>(
          getPublishedResourceQuery,
          {slug},
        )
      }),
    )
  }

  return {
    props: {
      preview,
      primaryResource,
      field: getField(primaryResource),
      initialInfiniteResources,
    },
  }
}

export default ResourcePage
