import {useContext, useEffect, useState} from 'react'

import * as Sentry from '@sentry/nextjs'
import {useQuery} from '@tanstack/react-query'
import $ from 'jquery'

import {useGPT} from '../../../core/ads/GPTContext'
import {insertRecommendedResourceAd} from '../../../core/ads/utils'
import {getInfiniteScrollResources} from '../../../core/api'
import HoverCard from '../../cards/HoverCard'
import {ResourceComponentContext} from '../ResourceComponent'

export default function RecommendedResources() {
  const {resource, preview, resourceIndex} = useContext(
    ResourceComponentContext,
  )
  const [recommendedResources, setRecommendedResources] = useState([])
  const {GPTHasLoaded} = useGPT()

  useQuery(
    [`recommendedResources_${resource.slug}`],
    getInfiniteScrollResources({
      roles: resource.roles,
      clinicalSpecialty: resource.clinicalSpecialty,
      primaryResourceSlug: resource.slug,
      templateType: resource.templateType,
      limit: 4,
    }),
    {
      enabled: !preview,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setRecommendedResources(data),
      onError: (err) => Sentry.captureException(err),
    },
  )

  useEffect(() => {
    if (resource.showAds && GPTHasLoaded && recommendedResources.length > 0) {
      const slots = []

      try {
        const recs = $(`.recommended__resources__${resourceIndex}`)
        const adClass = `recommended__resources__${resourceIndex} mt-md-0 mb-3 mt-3 ${
          resource.templateType === 'default'
            ? 'col-xs-12 col-sm-4'
            : 'col-xs-12 col-sm-3'
        }`

        if (recs.length >= 1) {
          insertRecommendedResourceAd(recs, adClass, 0, resource, slots)

          if (recs.length >= 4) {
            insertRecommendedResourceAd(recs, adClass, 3, resource, slots)
          }
        }
      } catch (e) {
        // continue regardless of error
      }

      return function cleanup() {
        if (googletag.destroySlots) {
          googletag.destroySlots(slots)
        }
      }
    }
  }, [
    resource,
    resource.showAds,
    GPTHasLoaded,
    recommendedResources,
    resource.templateType,
    resourceIndex,
  ])

  if (preview) {
    return <></>
  }

  return (
    <div className="row mt-5">
      {recommendedResources &&
        recommendedResources.map((recommendedResource) => (
          <HoverCard
            key={recommendedResource._id}
            url={`/resources/${recommendedResource.slug}/`}
            image={recommendedResource.featuredImage}
            heading={recommendedResource.category}
            subheading={recommendedResource.publishedAt}
            title={recommendedResource.title}
            colClass={`recommended__resources__${resourceIndex} ${
              resource.templateType === 'default'
                ? 'col-xs-12 col-sm-4'
                : 'col-xs-12 col-sm-3'
            }`}
          />
        ))}
    </div>
  )
}
