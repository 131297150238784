import {useEffect, useMemo} from 'react'

import {useGPT} from '../../core/ads/GPTContext'
import {createAd, getAdSpecs, shouldUseCustomAds} from '../../core/ads/utils'
import {Resource} from '../../pages/resources/[slug]'
import styles from '../../styles/ResourceAds.module.scss'

export default function BannerAd({
  adCode: initialAdCode,
  resource,
  margin = 'mb-3',
}: {
  adCode: string
  resource?: Resource
  margin?: string
}) {
  // If using custom ads, use the custom resource ad code, else use the provided adCode prop
  const adCode =
    shouldUseCustomAds(resource) && resource?.customAd?.headerCode
      ? resource.customAd.headerCode
      : initialAdCode
  // Use useMemo since adSpecs triggers re-rendering due to its usage in the useEffect
  const adSpecs = useMemo(
    () => getAdSpecs(adCode, 'banner', resource?._id),
    [adCode, resource?._id],
  )
  const {id: adId} = adSpecs

  const {GPTHasLoaded} = useGPT()

  // Create ad
  useEffect(() => {
    return createAd(adSpecs, GPTHasLoaded, resource)
  }, [adSpecs, GPTHasLoaded, resource])

  return (
    <div
      id={adId}
      className={`${styles.resourceHeaderAd} d-flex justify-content-center align-items-center ${margin}`}
    />
  )
}
