import {createContext, useEffect, useState} from 'react'

import * as Sentry from '@sentry/nextjs'

import {useUser} from '../../auth'
import {getAdExperimentSettings} from '../requests'

interface NpiAdsExperimentSettingsContext {
  isLoading: boolean
  settings:
    | {
        enabled: boolean
        resources: {
          _key: string
          document: {
            slug: {
              current: string
            }
          }
        }[]
        nontargetResourceHeaderAdUnitCode: string
        nontargetResourceInreadAdUnitCode: string
        nontargetResourceSidebarAdUnitCode: string
        nontargetResourceStickyFooterAdUnitCode: string
        targetResourceHeaderAdUnitCode: string
        targetResourceInreadAdUnitCode: string
        targetResourceSidebarAdUnitCode: string
        targetResourceStickyFooterAdUnitCode: string
      }
    | undefined
}

export const NpiAdsExperimentSettingsContext =
  createContext<NpiAdsExperimentSettingsContext>({
    isLoading: true,
    settings: undefined,
  })

interface NpiAdsExperimentSettingsProviderProps {
  children: React.ReactNode
}

export const NpiAdsExperimentSettingsProvider = ({
  children,
}: NpiAdsExperimentSettingsProviderProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [settings, setSettings] =
    useState<NpiAdsExperimentSettingsContext['settings']>()
  const {user, isLoading: isUserLoading} = useUser()

  useEffect(() => {
    if (isUserLoading) return
    fetchSettings()

    async function fetchSettings() {
      try {
        setSettings(await getAdExperimentSettings())
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error(error)
        } else {
          Sentry.captureException(error)
        }
      } finally {
        setIsLoading(false)
      }
    }
  }, [isUserLoading, user])

  return (
    <NpiAdsExperimentSettingsContext.Provider value={{isLoading, settings}}>
      {children}
    </NpiAdsExperimentSettingsContext.Provider>
  )
}
