import mp, {type Config} from 'mixpanel-browser'

import {ANALYTICS_ENABLED, MIXPANEL_PROJECT_TOKEN} from './config'

const inDevelopment = process.env.NODE_ENV !== 'production'

export function initializeMixpanel(config?: Partial<Config>) {
  if (ANALYTICS_ENABLED) {
    mp.init(MIXPANEL_PROJECT_TOKEN as string, {
      debug: inDevelopment,
      track_pageview: false,
      persistence: 'localStorage',
      ...config,
    })
  }
}

interface MixpanelProps {
  [key: string]: string
}

interface MixpanelResourceProps {
  'Contains Gated Content'?: string
  'Resource Role'?: string[]
  'Resource Category'?: string
  'Resource Slug'?: string
  'Resource Clinical Specialty'?: string
  'Resource Tags'?: string[]
  'Resource Media Type'?: string
  'Resource Author'?: string[]
}

export const mixpanel = {
  identify: (id: number | null) => {
    if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
    try {
      mp.identify(id?.toString())
    } catch (e) {
      if (inDevelopment) console.error('Mixpanel identify error: ', e)
      // else continue regardless of error
    }
  },
  get_distinct_id: () => {
    if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
    try {
      return mp.get_distinct_id()
    } catch (e) {
      // continue regardless of error
    }
  },
  alias: (newId: string, existingId: string) => {
    if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
    try {
      mp.alias(newId, existingId)
    } catch (e) {
      if (inDevelopment) console.error('Mixpanel alias error: ', e)
      // else continue regardless of error
    }
  },
  register: (props: MixpanelProps) => {
    if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
    try {
      mp.register(props)
    } catch (e) {
      if (inDevelopment) console.error('Mixpanel register error: ', e)
      // else continue regardless of error
    }
  },
  track: (action: string, props?: MixpanelProps) => {
    if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
    try {
      mp.track(action, props)
    } catch (e) {
      if (inDevelopment) console.error('Mixpanel track error: ', e)
      // else continue regardless of error
    }
  },
  trackResource: (props: MixpanelResourceProps) => {
    if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
    try {
      mp.track('Viewed Resource', props)
    } catch (e) {
      if (inDevelopment) console.error('Mixpanel track resource error: ', e)
      // continue regardless of error
    }
  },
  people: {
    set: (props: MixpanelProps) => {
      if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
      try {
        mp.people.set(props)
      } catch (e) {
        if (inDevelopment) console.error('Mixpanel people set error: ', e)
        // continue regardless of error
      }
    },
    set_once: (props: MixpanelProps) => {
      if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
      try {
        mp.people.set_once(props)
      } catch (e) {
        // continue regardless of error
      }
    },
  },
  reset: () => {
    if (!ANALYTICS_ENABLED || typeof window === 'undefined') return
    try {
      mp.reset()
    } catch (e) {
      // continue regardless of error
    }
  },
}
