import {trackFacebookCustomEvent} from '../facebook'
import {trackGoogleEvent} from '../google'
import {mixpanel} from '../mixpanel'

export function trackStartedSignUp(gatedContentSlug?: string) {
  // This function makes API calls to third party platforms for conversion tracking and analytics purposes.
  const page = gatedContentSlug ? 'resource-page' : 'sign-up-page'
  const eventName = gatedContentSlug
    ? 'Started Gated Content Form'
    : 'Started Sign Up Form'

  mixpanel.track(eventName, {
    'Resource Slug': gatedContentSlug ?? '',
    Page: page,
  })
  trackGoogleEvent('sign-up', {
    event_label: page,
  })
  trackFacebookCustomEvent(eventName, {
    page,
  })
}
