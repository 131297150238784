import {useContext} from 'react'

import SidebarAd from '../../ads/SidebarAd'
import SearchBar from '../../forms/SearchBar'
import {ResourceComponentContext} from '../ResourceComponent'
import AccessContentButton from './AccessContentButton'
import CustomSponsors from './CustomSponsors'
import SidebarLeadForm from './SidebarLeadForm'
import {SocialButtons} from './SocialButtons'
import Sponsors from './Sponsors'

export default function Sidebar() {
  const {resource, leadForm} = useContext(ResourceComponentContext)
  const showZeissLeadForm = [
    'carl-zeiss-meditec-professional-education',
  ].includes(resource.sponsor?.slug)

  return (
    <>
      <div className="d-none d-lg-flex pb-3">
        <SocialButtons />
      </div>
      <SearchBar
        placeholder="Search by category, topic, or keyword"
        containerClass="mt-3 mt-lg-0 mb-3"
        inputClass="me-2 rounded-1"
        buttonClass="btn-secondary rounded-1"
        displayIcon={false}
      />
      {resource.showSponsorsBlock &&
        (resource.customSponsors ? (
          <CustomSponsors {...resource.customSponsors} />
        ) : (
          <Sponsors />
        ))}
      <div className="sticky-top" style={{top: '90px', zIndex: 1}}>
        {showZeissLeadForm ? (
          <SidebarLeadForm
            buttonColor="primary"
            buttonCTAText="Access Resources"
            copy="Courses, videos, and whitepapers for the medically minded clinician."
            href="https://jobs.eyesoneyecare.com/companies/carl-zeiss-meditec-professional-education/"
            title="The Zeiss Professional Education Portal"
          />
        ) : (
          <>
            {resource.showAds && (
              <SidebarAd
                adCode="CovalentCareersResources_Sidebar"
                resource={resource}
              />
            )}
            {leadForm && (
              <SidebarLeadForm
                buttonCTAText={leadForm?.unfurledRef.buttonCTAText}
                href={leadForm?.unfurledRef.buttonUrl}
                copy={leadForm?.unfurledRef.sidebarCopy}
                buttonColor={leadForm?.unfurledRef.sidebarButtonColor}
                icon={leadForm?.unfurledRef.sidebarIcon}
                iconColor={leadForm?.unfurledRef.sidebarIconColor}
                title={leadForm?.unfurledRef.sidebarTitle}
              />
            )}
            {/* Display an Access Content button to move the user towards gated content */}
            {!leadForm && resource.containsGatedContent && (
              <div className="pb-5">
                <AccessContentButton className="btn btn-primary btn-lg text-white rounded w-100" />
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}
