import {useContext, useEffect, useMemo} from 'react'

import {useGPT} from '../../core/ads/GPTContext'
import {NpiAdsExperimentSettingsContext} from '../../core/ads/contexts/NpiAdsExperimentSettingsProvider'
import {createAd, getAdSpecs, shouldUseCustomAds} from '../../core/ads/utils'
import {useUser} from '../../core/auth'
import {Resource} from '../../pages/resources/[slug]'

export default function SidebarAd({
  adCode: initialAdCode,
  resource,
}: {
  adCode: string
  resource?: Resource
}) {
  const {
    isLoading: isAdsExperimentSettingsLoading,
    settings: adsExperimentSettings,
  } = useContext(NpiAdsExperimentSettingsContext)
  const {user, isLoading: isUserLoading} = useUser()
  // If using custom ads, use the custom resource ad code, else use the provided adSpecPath prop
  const adCode =
    shouldUseCustomAds(resource) && resource?.customAd?.sidebarCode
      ? resource.customAd.sidebarCode
      : adsExperimentSettings?.enabled &&
          resource &&
          adsExperimentSettings.resources
            .map((r) => r.document.slug.current)
            .includes(resource.slug)
        ? user
          ? adsExperimentSettings.targetResourceSidebarAdUnitCode
          : adsExperimentSettings.nontargetResourceSidebarAdUnitCode
        : initialAdCode
  // Use useMemo since adSpecs triggers re-rendering due to its usage in the useEffect
  const adSpecs = useMemo(
    () => getAdSpecs(adCode, 'sidebar', resource?._id),
    [adCode, resource?._id],
  )
  const {id: adId} = adSpecs

  const {GPTHasLoaded} = useGPT()

  // Create ad
  useEffect(() => {
    if (!isAdsExperimentSettingsLoading && !isUserLoading)
      return createAd(adSpecs, GPTHasLoaded, resource)
  }, [
    adSpecs,
    GPTHasLoaded,
    isAdsExperimentSettingsLoading,
    isUserLoading,
    resource,
  ])

  return (
    <div className="d-none d-xl-flex mt-3 mb-3 justify-content-center align-items-center resource-sidebar-ad">
      <div id={adId} />
    </div>
  )
}
