import {trackAdRollEvent} from '../adroll'
import {G_AW_ID} from '../config'
import {trackFacebookCustomEvent, trackFacebookEvent} from '../facebook'
import {trackGoogleEvent} from '../google'
import {trackLinkedInEvent} from '../linkedin'
import {mixpanel} from '../mixpanel'
import {parselyTrackLeadCapture} from '../parsely'
import {trackTwitterEvent, twitterEventIdMap} from '../twitter'

export async function trackCompletedSignUp(
  userId: string,
  email: string,
  title: string,
  gatedContentSlug: string,
  roles: string[],
) {
  // This function makes API calls to third party platforms for conversion tracking and analytics purposes.

  if (userId) {
    const existingUserId = mixpanel.get_distinct_id()

    if (existingUserId) {
      mixpanel.alias(userId, existingUserId)
    }
  }

  // if resource page:
  if (title && gatedContentSlug) {
    // mixpanel
    mixpanel.track('Submitted Gated Content Form', {
      'Resource Slug': gatedContentSlug,
    })
    mixpanel.track('Gated Content Sign Up', {
      'Gated Content Slug': gatedContentSlug,
    })
    mixpanel.people.set({
      'Original Gated Content Slug': gatedContentSlug,
    })

    // Parsely
    parselyTrackLeadCapture('EOE - Submit Gated Content Form')

    // Google Analytics
    trackGoogleEvent('content-download', {
      event_category: 'gated-content',
      event_label: 'resource-page',
    })
    trackGoogleEvent('conversion', {
      send_to: `${G_AW_ID}/7vIUCJGkg6oBEJXUicYD`,
    })
    // role-specific events
    if (roles) {
      if (roles.length === 1 && roles.indexOf('Ophthalmologist') >= 0) {
        trackGoogleEvent('content-download', {
          event_category: 'gated-content-ophthalmology',
          event_label: 'resource-page',
        })
        trackTwitterEvent(twitterEventIdMap.ophthalmology['content downloads'])
      }
      if (roles.indexOf('Optometrist') >= 0) {
        trackGoogleEvent('content-download', {
          event_category: 'gated-content-optometry',
          event_label: 'resource-page',
        })
      }
    }

    // AdRoll
    trackAdRollEvent('72ecbc8e')

    // FB
    trackFacebookCustomEvent('ContentDownload')
    trackFacebookEvent('CompleteRegistration', {
      content_name: title,
    })

    // LinkedIn
    trackLinkedInEvent(4645348)
  } else {
    // if sign-up page:
    mixpanel.track('Sign Up')
    trackGoogleEvent('registered', {
      event_category: 'signup-form',
      event_label: 'sign-up-page',
    })
    trackGoogleEvent('conversion', {
      send_to: `${G_AW_ID}/o0uoCI2DxZoBEJXUicYD`,
    })
    trackAdRollEvent('b29b6807')
    trackFacebookEvent('CompleteRegistration')
    parselyTrackLeadCapture('EOE - Signup')
  }
}
