import {FC, useEffect, useState} from 'react'
import {CloseButton, Modal} from 'react-bootstrap'

import {PortableText} from '@portabletext/react'
import {PortableTextBlock} from '@portabletext/types'
import Cookies from 'js-cookie'
import {useLocalStorage} from 'usehooks-ts'

import SanityImage from '../../components/sanity/SanityImage'
import {useUser} from '../../core/auth'
import {useConfetti} from '../../core/hooks/useConfetti'
import serializers from '../../core/serializers'
import {scaleDimensions} from '../../core/utils'
import {trpc} from '../../utils/trpc'
import {mixpanel} from '../analytics/mixpanel'

export type NPIFormProps = {
  copy: PortableTextBlock[]
  finePrint: PortableTextBlock[]
  headline: string
  image: {
    _type: 'image'
    asset: {
      _ref: string
      _type: 'reference'
    }
    dimensions: {
      aspectRatio: number
      height: number
      width: number
    }
  }
  submitButtonText: string
  _key: string
  _type: 'npiForm'
}
const NPIForm: FC<NPIFormProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const {addConfetti} = useConfetti()

  // Check if the user has an NPI number in hubspot
  const {user} = useUser()
  const [cookiedUUID] = useLocalStorage<number | null>('eoeUUID', null)
  const uuid = String(user?.profile?.uuid ?? cookiedUUID)
  const hasSeenNPIForm = Cookies.get('hasSeenNPIForm')
  const {data: npiData, isSuccess: npiSuccess} = trpc.auth.getNPI.useQuery(
    {uuid},
    {enabled: !!uuid && !hasSeenNPIForm},
  )
  const useSetNPI = trpc.auth.setNPI.useMutation()

  // If we have a user id (from auth or cookie) but not an NPI, show the modal
  useEffect(() => {
    if (npiSuccess && !npiData.npi && !hasSeenNPIForm) {
      setIsOpen(true)
      Cookies.set('hasSeenNPIForm', 'true', {expires: 30})
      mixpanel.track('NPI Popup Impression', {uuid})
    }
  }, [hasSeenNPIForm, npiData, npiSuccess, uuid])

  const [formNPI, setFormNPI] = useState('')
  const [showNPIError, setShowNPIError] = useState(false)

  const useValidateNPI = trpc.auth.validateNPI.useMutation({
    onSuccess: (validNPI) => {
      if (validNPI) {
        setIsOpen(false)
        mixpanel.track('NPI Popup Submission', {
          userUUID: uuid,
          npi: formNPI,
        })
        addConfetti()

        // uuid is implicitly always set here since we only show the modal if we have a uuid
        if (uuid) {
          useSetNPI.mutate({npi: formNPI, uuid})
        }
      } else {
        setShowNPIError(true)
      }
    },
  })

  const [width, height] = scaleDimensions(
    props.image.dimensions.width,
    props.image.dimensions.height,
    200,
  )

  return (
    <Modal
      show={isOpen}
      onHide={() => {
        setIsOpen(false)
      }}
      centered
    >
      <Modal.Body>
        <CloseButton className={'float-end'} onClick={() => setIsOpen(false)} />
        <div className={'px-3 px-md-4 px-lg-5'}>
          <div className={'text-center pt-4'}>
            <SanityImage
              src={props.image}
              alt={'npiform'}
              width={width}
              height={height}
              className=""
            />
          </div>
          <h3 className="text-center">{props.headline}</h3>

          <div className={'text-center'}>
            {/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */}
            <PortableText value={props.copy} components={serializers as any} />
          </div>

          <div className="d-flex flex-column">
            <div className="mb-2">
              <div className="form-label mb-0">
                Enter your NPI number<span className="text-danger">*</span>
              </div>
              <input
                className="form-control"
                value={formNPI}
                onChange={(e) => setFormNPI(e.target.value)}
              />
              <small className="text-muted pb-0">
                You can look up your NPI number{' '}
                <a
                  className="text-primary"
                  href="https://www.npinumberlookup.org/"
                  target="_blank"
                >
                  here
                </a>
                .
              </small>
              {showNPIError && (
                <div className="text-danger text-small">
                  Invalid NPI number. Please try again.
                </div>
              )}
            </div>
          </div>

          <div className={'text-center text-xs text-muted'}>
            {/* eslint-disable @typescript-eslint/no-explicit-any */}
            <PortableText
              components={serializers as any}
              value={props.finePrint}
            />
            {/* eslint-enable @typescript-eslint/no-explicit-any */}
          </div>

          {/* Submit button */}
          <div className="d-flex justify-content-center mb-4">
            <button
              className="btn btn-lg btn-primary"
              onClick={() => {
                useValidateNPI.mutate({npi: formNPI})
              }}
            >
              {props.submitButtonText}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default NPIForm
