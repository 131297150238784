import {createContext, useEffect, useState} from 'react'
import {CloseButton, Modal} from 'react-bootstrap'

import {useQueryClient} from '@tanstack/react-query'
import {getQueryKey} from '@trpc/react-query'
import {IncomingMessage} from 'http'
import {useLocalStorage} from 'usehooks-ts'
import {z} from 'zod'

import {mixpanel} from '../components/analytics/mixpanel'
import {AuthModal} from '../components/auth/AuthModal'
import {ImpersonationNav} from '../components/auth/ImpersonationNav'
import {trpc} from '../utils/trpc'
import {baseBackendUrl} from './api'
import {User} from './auth/types'

export const UserProfileSchema = z.object({
  id: z.number(),
  created_datetime: z.string(),
  modified_datetime: z.string(),
  metadata: z.object({}).passthrough().nullable(),
  uuid: z.string(),
  name: z.string(),
  first_name: z.string(),
  last_name: z.string().nullable(),
  country: z.string().length(2),
  role: z.enum([
    'Optometrist',
    'Ophthalmologist',
    'Optician',
    'Optometric Technician',
    'Ophthalmic Technician',
    'Practice Staff',
    'Industry Professional',
    'Consumer/Patient',
  ]),
  graduation_year: z.string().nullable(),
  is_practice_owner: z.boolean().nullable(),
  specialties: z.array(z.string()).nullable(),
  do_not_email: z.boolean().default(false),
})

export function useUser() {
  const {data: user, ...userQuery} = trpc.auth.getUser.useQuery(undefined, {
    refetchOnWindowFocus: false,
  })
  return {user, ...userQuery}
}

export default function useSignOut() {
  const queryClient = useQueryClient()
  const {mutate: signOut, ...signOutMutation} = trpc.auth.signOut.useMutation({
    onError: () => {
      alert('There was an error signing out. Please try again.')
    },
    onSuccess: () => {
      // `reset` is called so that the `getUser` cache is immediately reset to its initial value
      // of no user so that any queries that are dependent on a user are immediately notified of the sign out.
      queryClient.resetQueries(getQueryKey(trpc.auth.getUser))

      // clears any tRPC procedures with names that begin with `protected`;
      // procedures that begin with the term `protected` are intended to only
      // be accessed by a user that is authenticated; when a user signs out,
      // any local state (including caches) should immediately reflect that.
      // thus, this `removeQueries` call is made to immediately clear any local
      // query caches for "protected" procedures.
      queryClient.removeQueries({
        predicate: (query) =>
          Array.isArray(query.queryKey) &&
          Array.isArray(query.queryKey[0]) &&
          query.queryKey[0].length === 2 &&
          query.queryKey[0][1].startsWith('protected'),
      })
    },
  })
  return {signOut, ...signOutMutation}
}

interface AuthContext {
  openAuthModal(): void
}

export const AuthContext = createContext<AuthContext>({
  openAuthModal: () => void 0,
} as AuthContext)

interface AuthProviderProps {
  children: React.ReactNode
}

export const AuthProvider = (props: AuthProviderProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const {user} = useUser()
  const [, setCookiedUUID] = useLocalStorage<string | null>('eoeUUID', null)

  useEffect(() => {
    if (user) {
      // Set a cross-platform cookie with the user's ID for tracking
      if (user?.profile?.uuid) {
        setCookiedUUID(user.profile.uuid)
      }

      mixpanel.identify(user.id)
      mixpanel.people.set({
        $email: user.email,
        $first_name: user.profile?.first_name ?? '',
        $last_name: user.profile?.last_name ?? '',
        $full_name: user.profile?.name ?? '',
        'Contact UUID': user.id.toString(),
        Role: user.profile?.role ?? '',
      })
      mixpanel.register({
        'Contact UUID': user.id.toString(),
      })

      const existingUserId = mixpanel.get_distinct_id()
      if (existingUserId) {
        mixpanel.alias(user.id.toString(), existingUserId)
      }
    } else {
      mixpanel.reset()
    }
  }, [setCookiedUUID, user])

  const openAuthModal = () => setIsOpen(true)

  return (
    <AuthContext.Provider
      value={{
        openAuthModal,
      }}
    >
      {user?.impersonation_release_url && (
        <ImpersonationNav
          email={user.email}
          releaseUrl={user.impersonation_release_url}
        />
      )}
      {props.children}
      <Modal show={isOpen} onHide={() => setIsOpen(false)} centered size="lg">
        <Modal.Body className="p-4 p-lg-5">
          <CloseButton
            className={'float-end'}
            onClick={() => setIsOpen(false)}
          />
          <AuthModal />
        </Modal.Body>
      </Modal>
    </AuthContext.Provider>
  )
}

// Intended for server-side use (SSR pages and API routes). Gets the current
// user using session cookies sent with the original request.
export const getUser = async (
  req: IncomingMessage,
  scope = '',
): Promise<User | null> => {
  const response = await fetch(
    `${baseBackendUrl}/auth/me/${scope ? `?scope=${scope}` : ''}`,
    {
      headers: {
        cookie: req.headers.cookie || '',
      },
    },
  )
  const data = await response.json()
  return data?.user ?? null
}
