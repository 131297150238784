import {useEffect, useRef} from 'react'

import * as Sentry from '@sentry/nextjs'

import {trpc} from '../../../utils/trpc'
import {useUser} from '../../auth'
import {useGPT} from '../GPTContext'

/**
 * Hook that sets up ad impressions and click tracking for authenticated users viewing a resource.
 */
export function useAdTracking(resourceSlug: string) {
  const {mutate: track} =
    trpc.analytics.protectedTrackGenericUserEvent.useMutation()
  const {GPTHasLoaded} = useGPT()
  const {user} = useUser()
  // use refs instead of state to avoid re-rendering the component when the set of tracked ad elements changes.
  // this prevents multiple intervals from being set up, which would result in multiple ad impression events being sent for the same ad element.
  const trackedAdElements = useRef<Set<string>>(new Set())
  const visibilityTimestamps = useRef<
    Record<string, {adUnit: string; visibleSince: number} | null>
  >({})

  // this hook sends a "Basic Impression" event when the ad unit has been loaded.
  useEffect(() => {
    if (!GPTHasLoaded || !user || !resourceSlug) return

    function onSlotRenderEnded(event: googletag.events.SlotRenderEndedEvent) {
      const adUnit = event.slot.getAdUnitPath()
      trackImpression(adUnit)
    }

    googletag.cmd.push(() => {
      googletag.pubads().addEventListener('slotRenderEnded', onSlotRenderEnded)
    })

    return () => {
      googletag.cmd.push(() => {
        googletag
          .pubads()
          .removeEventListener('slotRenderEnded', onSlotRenderEnded)
      })
    }

    async function trackImpression(adUnit: string) {
      if (!user) return
      await track(
        {
          event: 'Basic Impression',
          properties: {
            adUnit,
          },
        },
        {
          onError: (e) => {
            if (process.env.NODE_ENV === 'development') {
              console.error(e)
            } else {
              Sentry.captureException(e)
            }
          },
        },
      )
    }
  }, [GPTHasLoaded, resourceSlug, track, user])

  // this hook listens for visibility changes on individual ad elements and records their associated ad slot and the time they became visible;
  // it also removes elements that are no longer visible from the visibilityTimestamps object if they haven't been visible for at least 1 second.
  useEffect(() => {
    if (!GPTHasLoaded || !user || !resourceSlug) return

    function onSlotVisibilityChanged(
      event: googletag.events.SlotVisibilityChangedEvent,
    ) {
      const adElementId = event.slot.getSlotElementId()
      const adUnit = event.slot.getAdUnitPath()

      if (!trackedAdElements.current.has(adElementId)) {
        if (event.inViewPercentage > 50) {
          if (!visibilityTimestamps.current[adElementId]) {
            visibilityTimestamps.current[adElementId] = {
              adUnit,
              visibleSince: Date.now(),
            }
          }
        } else {
          if (visibilityTimestamps.current[adElementId]) {
            visibilityTimestamps.current[adElementId] = null
          }
        }
      }
    }

    googletag.cmd.push(() => {
      googletag
        .pubads()
        .addEventListener('slotVisibilityChanged', onSlotVisibilityChanged)
    })

    return () => {
      googletag.cmd.push(() => {
        googletag
          .pubads()
          .removeEventListener('slotVisibilityChanged', onSlotVisibilityChanged)
      })
    }
  }, [GPTHasLoaded, resourceSlug, user])

  // this hook sends ad impression events to the server for ad elements that have been visible for at least 1 second
  useEffect(() => {
    if (!visibilityTimestamps.current) return

    const interval = setInterval(() => {
      const now = Date.now()

      for (const [adElementId, visibilityData] of Object.entries(
        visibilityTimestamps.current,
      )) {
        if (
          !trackedAdElements.current.has(adElementId) &&
          visibilityData &&
          now - visibilityData.visibleSince >= 1000
        ) {
          trackImpression(visibilityData.adUnit)
          trackedAdElements.current.add(adElementId)
        }
      }
    }, 250)

    return () => clearInterval(interval)

    async function trackImpression(adUnit: string) {
      if (!user) return
      await track(
        {
          event: 'Saw Slot',
          properties: {
            adUnit,
          },
        },
        {
          onError: (e) => {
            if (process.env.NODE_ENV === 'development') {
              console.error(e)
            } else {
              Sentry.captureException(e)
            }
          },
        },
      )
    }
  }, [track, user])

  // iframe event listener
  useEffect(() => {
    function iframeFunction() {
      setTimeout(() => {
        if (
          document.activeElement &&
          document.activeElement.tagName === 'IFRAME'
        ) {
          // prevent the iframe from stealing focus and thus breaking tracking if user clicks on another iframe
          setTimeout(() => {
            window.focus()
          }, 1)
          trackClick(
            document.activeElement.id,
            document.activeElement.parentElement?.id,
          )
        }
      })
    }

    async function trackClick(iframeId: string, parentElementId?: string) {
      if (!user) return
      await track(
        {
          event: 'Clicked IFrame',
          properties: {
            iframeId,
            parentElementId,
          },
        },
        {
          onError: (e) => {
            if (process.env.NODE_ENV === 'development') {
              console.error(e)
            } else {
              Sentry.captureException(e)
            }
          },
        },
      )
    }

    addEventListener('blur', iframeFunction)
    return () => removeEventListener('blur', iframeFunction)
  }, [track, user])
}
