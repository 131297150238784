import React, {useContext, useEffect, useState} from 'react'

import {AuthContext, useUser} from '../../core/auth'
import {trpc} from '../../utils/trpc'
import {mixpanel} from '../analytics/mixpanel'
import {ResourceComponentContext} from '../resources/ResourceComponent'

export const RatingWidget = () => {
  const {resource} = useContext(ResourceComponentContext)
  const {openAuthModal} = useContext(AuthContext)
  const {user} = useUser()
  const [success, setSuccess] = useState(false)
  const [visible, setVisible] = useState(true)
  const id = resource._id

  const {mutate: logRating} = trpc.analytics.protectedLogRating.useMutation()
  const handleButtonClick = (value: number) => {
    if (!user) {
      openAuthModal()
    } else {
      try {
        logRating({sanityId: id, rating: value})
        sessionStorage.setItem(`${id}_rated`, 'true')
        mixpanel.track('Rated Content Quality', {
          slug: resource.slug,
          rating: value.toString(),
        })
      } catch (e) {
        // continue regardless of error
      }
      // Show success state regardless of response
      setSuccess(true)
    }
  }

  // Check session storage on mount to see if the user has already rated the resource
  useEffect(() => {
    const isRated = sessionStorage.getItem(`${id}_rated`) === 'true'
    setVisible(!isRated)
  }, [id])

  if (!visible) return null

  return (
    <div className="card mt-4 mt-lg-0 mb-lg-4">
      <div className="card-body">
        {success ? (
          <h5>
            Thanks for rating this content! Your feedback helps us improve.
          </h5>
        ) : (
          <div>
            <h5>How would you rate the quality of this content?</h5>
            <div className="btn-group w-100">
              {[...Array(10)].map((_, index) => (
                <button
                  key={index}
                  className="btn btn-outline-primary btn-sm py-lg-3"
                  onClick={() => handleButtonClick(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
