import {sanity} from '../sanity'

export async function getAdExperimentSettings() {
  return await sanity.fetch(
    `*[_type == "npiAdTargetingExperiment" && _id == "npiTargetedAdSettings"][0]{
      ...,
      resources[]{
        _key,
        "document": @->{
          slug
        }
      }  
    }`,
  )
}
