export const ANALYTICS_ENABLED = process.env.NODE_ENV === 'production'
export const ADROLL_ADV_ID = process.env.NEXT_PUBLIC_ADROLL_ADV_ID
export const ADROLL_PIX_ID = process.env.NEXT_PUBLIC_ADROLL_PIX_ID
export const FB_CC_PIXEL_ID = process.env.NEXT_PUBLIC_FB_CC_PIXEL_ID
export const FB_OPHTHALMOLOGY_PIXEL_ID =
  process.env.NEXT_PUBLIC_FB_OPHTHALMOLOGY_PIXEL_ID
export const G_AW_ID = process.env.NEXT_PUBLIC_GOOGLE_ADWORDS_ID
export const G_GA4_ID = process.env.NEXT_PUBLIC_GOOGLE_GA4_ID
export const G_UA_ID = process.env.NEXT_PUBLIC_GOOGLE_UA_ID
export const LINKEDIN_PARTNER_ID = process.env.NEXT_PUBLIC_LI_INSIGHT_TAG_ID
export const MIXPANEL_PROJECT_TOKEN =
  process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN
export const TWITTER_OPTOMETRY_ID =
  process.env.NEXT_PUBLIC_TWITTER_OPTOMETRY_PIXEL_ID
export const TWITTER_OPHTHALMOLOGY_ID =
  process.env.NEXT_PUBLIC_TWITTER_OPHTHALMOLOGY_PIXEL_ID
export const PARSELY_SITE_ID = process.env.NEXT_PUBLIC_PARSELY_SITE_ID
