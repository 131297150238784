import {useContext, useState} from 'react'

import Link from 'next/link'

import {faSparkles} from '@fortawesome/pro-duotone-svg-icons'
import {faCalendar, faEye, faStopwatch} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PortableText} from '@portabletext/react'

import {NpiAdsExperimentSettingsContext} from '../../../core/ads/contexts/NpiAdsExperimentSettingsProvider'
import {useUser} from '../../../core/auth'
import useHasMounted from '../../../core/hooks/useHasMounted'
import {featuredTextSerializers} from '../../../core/serializers'
import {displayHitCount, displayPublishedDate} from '../../../core/utils'
import BannerAd from '../../ads/BannerAd'
import SanityImage from '../../sanity/SanityImage'
import {ResourceComponentContext} from '../ResourceComponent'
import {SocialButtons} from './SocialButtons'

export default function ResourceHeader() {
  const {resource, hits} = useContext(ResourceComponentContext)
  const {
    isLoading: isAdsExperimentSettingsLoading,
    settings: adsExperimentSettings,
  } = useContext(NpiAdsExperimentSettingsContext)
  const {user, isLoading: isUserLoading} = useUser()
  const hasMounted = useHasMounted()
  // Show all authors for specific resources
  const showAllAuthorsResources = ['ultimate-guide-ocular-health-hygiene']
  const [showMoreAuthors, setShowMoreAuthors] = useState(
    showAllAuthorsResources.includes(resource.slug),
  )

  if (!resource) return <></>
  return (
    <div>
      {resource.showAds &&
        !isAdsExperimentSettingsLoading &&
        !isUserLoading && (
          <div className="mb-3">
            <BannerAd
              adCode={
                adsExperimentSettings?.enabled &&
                resource &&
                adsExperimentSettings.resources
                  .map((r) => r.document.slug.current)
                  .includes(resource.slug)
                  ? user
                    ? adsExperimentSettings.targetResourceHeaderAdUnitCode
                    : adsExperimentSettings.nontargetResourceHeaderAdUnitCode
                  : 'CovalentCareersResources_InRead'
              }
              resource={resource}
            />
          </div>
        )}
      {resource.clinicalSpecialty && (
        <div className="text-uppercase col-12 d-flex align-items-center mt-2 mt-md-0">
          <span style={{fontSize: '.8rem'}}>
            Published in{' '}
            {resource.tags?.includes('interventional mindset') ? (
              <Link href="/interventional-mindset" className="text-primary">
                Interventional Mindset
              </Link>
            ) : (
              <a
                className="text-primary"
                href={`/resources/?clinical_specialty=${resource.clinicalSpecialty}`}
              >
                {resource.clinicalSpecialty}
              </a>
            )}
          </span>
        </div>
      )}
      {resource.titleOverride ? (
        <h1>
          <PortableText
            value={resource.titleOverride}
            components={featuredTextSerializers}
          />
        </h1>
      ) : (
        <h1>{resource.title}</h1>
      )}
      <div
        className="d-flex align-items-center flex-wrap mb-2"
        style={{gap: '0.25rem'}}
      >
        {resource.authors?.slice(0, 4).map((author) => (
          <Link
            href={`/authors/${author.slug}/`}
            key={author._id}
            className="d-inline-flex align-items-center pr-3 me-3"
          >
            {author.image && (
              <SanityImage
                src={author.image}
                alt={author.name}
                width={25}
                height={25}
                className="avatar"
              />
            )}
            <p className="ms-1 mb-0">{author.name}</p>
          </Link>
        ))}
        {resource.authors?.length > 4 && !showMoreAuthors && (
          <div className="d-inline-flex align-items-center">
            <span
              role="button"
              className="link-primary"
              onClick={() => setShowMoreAuthors(true)}
            >
              et al.
            </span>
          </div>
        )}
        {resource.authors?.length > 4 &&
          showMoreAuthors &&
          resource.authors?.slice(4).map((author) => (
            <Link
              href={`/authors/${author.slug}/`}
              key={author._id}
              className="d-inline-flex align-items-center pr-3 me-3"
            >
              {author.image && (
                <SanityImage
                  src={author.image}
                  alt={author.name}
                  width={25}
                  height={25}
                  className="avatar"
                />
              )}
              <p className="ms-1 mb-0">{author.name}</p>
            </Link>
          ))}
      </div>
      {resource.disclosureOverride ? (
        <div className="mb-3">
          <div className="d-inline-flex badge bg-light text-wrap text-left text-black fw-semibold mb-0 paragraph-inherit-styles">
            <FontAwesomeIcon icon={faSparkles} className="text-warning me-1" />
            <PortableText
              value={resource.disclosureOverride}
              components={featuredTextSerializers}
            />
          </div>
        </div>
      ) : (
        resource.disclosure && (
          <div className="mb-3">
            <span className="badge bg-light text-wrap text-left text-black fw-semibold">
              <FontAwesomeIcon
                icon={faSparkles}
                className="text-warning me-1"
              />
              {resource.disclosure}
            </span>
          </div>
        )
      )}
      <div
        className="d-flex flex-wrap p-1 mb-3 border-top border-bottom text-gray-800 blocked"
        style={{fontSize: '.8rem'}}
      >
        {hasMounted && (
          <div className="d-flex align-items-center me-4">
            <FontAwesomeIcon icon={faCalendar} className="me-2 text-gray-500" />
            <span>
              <time dateTime={resource.publishedAt}>
                {displayPublishedDate(resource.publishedAt, 'short')}
              </time>
            </span>
          </div>
        )}
        {resource.showETR && (
          <div className="d-flex align-items-center me-4">
            <span>
              <FontAwesomeIcon
                icon={faStopwatch}
                className="me-2 text-gray-500"
              />
              {resource.estimatedReadTime} min read
            </span>
          </div>
        )}
        {hasMounted && hits && hits >= 100 && (
          <div className="d-flex align-items-center">
            <span>
              <FontAwesomeIcon icon={faEye} className="me-2 text-gray-500" />
              {displayHitCount(hits)} views
            </span>
          </div>
        )}
      </div>
      <div className="d-flex d-lg-none pb-3">
        <SocialButtons />
      </div>
    </div>
  )
}
