import {useEffect, useState} from 'react'

import DefaultMobileNav from '../nav/DefaultMobileNav'
import IndexField from '../nav/IndexField'
import ResourceField from '../nav/ResourceField'
import ResubscribeAlert from '../nav/ResubscribeAlert'
import JobsDropdown from '../nav/dropdowns/JobsDropdown'
import ResourcesDropdown from '../nav/dropdowns/ResourcesDropdown'
import UserDropdown from '../nav/dropdowns/UserDropdown'

export default function DefaultNav({customNavLogo, field, resource}) {
  const [shadow, setShadow] = useState(false)

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 0) {
        setShadow(true)
      } else {
        setShadow(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <DefaultMobileNav />
      <nav
        id="navSticky"
        className={`sticky-top bg-white d-none d-lg-block p-0 ${
          shadow ? 'shadow' : ''
        }`}
      >
        <div className="container py-3">
          <div className="d-flex justify-content-between align-items-center">
            {resource ? (
              <ResourceField resource={resource} field={field} />
            ) : (
              <IndexField customNavLogo={customNavLogo} field={field} />
            )}
            <div className="d-flex justify-content-end align-items-center">
              <ResourcesDropdown />
              <a
                href="https://glance.eyesoneyecare.com/"
                className="text-small me-3"
              >
                Glance
              </a>
              <a
                href="https://events.eyesoneyecare.com/"
                className="text-small me-3"
              >
                Events
              </a>
              <a
                href="https://courses.eyesoneyecare.com/"
                className="text-small me-3"
              >
                Courses
              </a>
              <JobsDropdown />
              <UserDropdown />
            </div>
          </div>
        </div>
        <ResubscribeAlert />
      </nav>
    </>
  )
}
