import Script from 'next/script'

export function HubspotInit() {
  return (
    <Script
      src="//js.hs-scripts.com/4099792.js"
      id="hs-script-loader"
      strategy="afterInteractive"
      type="text/plain"
      data-cookieconsent="marketing"
    />
  )
}
