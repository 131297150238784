import {useContext, useEffect, useMemo} from 'react'

import {useGPT} from '../../../core/ads/GPTContext'
import {createAd, getAdSpecs, shouldUseCustomAds} from '../../../core/ads/utils'
import {ResourceComponentContext} from '../ResourceComponent'
import {ResourceProjection} from '../schemas'

export default function CTAWidget() {
  const {resource} = useContext(ResourceComponentContext)
  const adCode =
    shouldUseCustomAds(resource) && resource.customAd?.ctaWidgetCode
      ? resource.customAd.ctaWidgetCode
      : 'eoe_cta_widget'
  // Use useMemo since adSpecs triggers re-rendering due to its usage in the useEffect
  const adSpecs = useMemo(
    () => getAdSpecs(adCode, 'ctaWidget', resource._id),
    [adCode, resource._id],
  )
  const {id: adId} = adSpecs

  const {GPTHasLoaded} = useGPT()

  function shouldShowAd(resource: ResourceProjection) {
    function duringCustomAdTakeover(resource: ResourceProjection) {
      if (resource.useCustomAds && resource.customAdsEndDateTime) {
        const currentDate = new Date()
        const customAdsEndDate = new Date(resource.customAdsEndDateTime)
        return currentDate < customAdsEndDate
      }
      return false
    }

    if (resource && resource.showAds && resource.showCTAWidget) {
      // Only show ad during custom ad takeover if there is a custom cta widget code
      if (duringCustomAdTakeover(resource)) {
        if (resource.customAd?.ctaWidgetCode) {
          return true
        } else {
          return false
        }
      }
      return true
    }
    return false
  }

  // Create ad
  useEffect(() => {
    if (shouldShowAd(resource)) {
      return createAd(adSpecs, GPTHasLoaded, resource, undefined, false)
    }
  }, [adSpecs, GPTHasLoaded, resource])

  if (shouldShowAd(resource)) {
    return <div id={adId} className="my-4" />
  } else return null
}
