import Image from 'next/legacy/image'

export default function Sponsors() {
  return (
    <div className="my-4 py-3 border-top border-bottom">
      <div className="d-flex align-items-center justify-content-center mb-2">
        <span className="sans-serif rounded-0 m-0 p-0 text-gray-500 text-center text-small">
          Eyes On Eyecare Site Sponsors
        </span>
      </div>
      <div className="d-flex justify-content-around align-items-center">
        <a
          href="https://izervayecp.com/?utm_medium=display&utm_source=8725557&utm_campaign=31794306&utm_content=0&utm_term=391425456"
          target="_blank"
          className="pt-2"
          rel="noreferrer noopener"
        >
          <Image
            src="/logos/companies/astellas-logo.png"
            alt="Astellas Logo"
            layout="intrinsic"
            width={110}
            height={30}
          />
        </a>
        <a
          href="https://information.lumenis.com/optilight?utm_source=eoe&utm_medium=spc&utm_campaign=b2b_be_the_doctor_eoe_us&utm_content=logo_adv"
          target="_blank"
          className="pt-2"
          rel="noreferrer noopener"
        >
          <Image
            src="/logos/companies/optilight-by-lumenis-logo-updated.png"
            alt="Optilight by Lumenis Logo"
            layout="intrinsic"
            width={139}
            height={30}
          />
        </a>
      </div>
    </div>
  )
}
